import axios from 'axios';
import { PRODUCTS_URL } from '../../layouts/API';
import { PRODUCTS_URL2 } from '../../layouts/API';

const GetAllProductsSlice = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${PRODUCTS_URL2}?return_all=1`,
		headers: headers,
		props: { ...data },
	});
	return response.data;
};

const GetAllProductsTypesSlice = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${PRODUCTS_URL2}/types`,
		headers: headers,
		props: { ...data },
	});
	return response.data;
};

const GetSingleProductsSlice = async (product_id, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${PRODUCTS_URL2}/single/${product_id}`,
		headers: headers,
	});
	return response.data;
};

const CreateProductMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${PRODUCTS_URL2}/store`,
		headers: headers,
		data: data,
	});
	return response.data;
};

const UpdateProductMethod = async (data, token) => {
	let headers = {
		Authorization: `Bearer ${token}`,
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${PRODUCTS_URL2}/update/${data?.product_id}`,
		headers: headers,
		data: data?.form_data,
	});
	return response.data;
};

const DeleteProductSlice = async (product_id, token) => {
	let headers = {
		Accept: 'aplication/json',
		Authorization: `Bearer ${token}`,
	};

	let response = await axios({
		method: 'DELETE',
		baseURL: `${PRODUCTS_URL2}/delete/${product_id}`,
		headers: headers,
	});
	return response.data;
};

const ProductService = {
	GetAllProductsSlice,
	GetAllProductsTypesSlice,
	GetSingleProductsSlice,
	CreateProductMethod,
	UpdateProductMethod,
	DeleteProductSlice,
};

export default ProductService;
