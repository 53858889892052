import React, { useEffect, useState } from 'react';
import { FormContainer } from '../../layouts/Styled/buttons/FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormik } from 'formik';
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { Add, CloudUpload, Create, Mail } from '@material-ui/icons';
import styled from 'styled-components';
import { HeaderStyle2 } from '../../layouts/Styled/HeaderStyle';
import { FormButtonStyle } from '../../layouts/Styled/buttons/submitButton';
import {
	CreateProductMethod,
	productReset,
} from '../../redux/ProjectSlice/ProductsSlice';
import { toast } from 'react-toastify';
import { GetAllStatesMethod, GetAllProductTypesMethod } from '../../Components/HelperMethods/APISMethodsHelper';
import {
	HandleSuccessNotifications,
	HendleErrorsNotifications,
} from '../../Components/HelperMethods/HandleNotifications';

const CreateProduct = () => {
	const dispatch = useDispatch();
	const { message,product_types, isSuccess, isError, isLoading, errors, responseCode } =
		useSelector((state) => state.products);

	const { all_states } = useSelector((state) => state.all_states);

	const AllTypes = product_types?.data;
	const AllStates = all_states?.data?.states;

	const [formSubmit, setFormSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			company_name 	: '',
			company_email 	: '',
			delivery_time 	: '',
			head_office_address 	: '',
			is_delivery 	: '',
			covered_states 	: [],
		},
		onSubmit: async (values) => {
			const form_data = new FormData();
			form_data.append('company_name', values?.company_name);
			form_data.append('company_email', values?.company_email);
			form_data.append('covered_states', values?.covered_states);
			form_data.append('delivery_time', values?.delivery_time);
			form_data.append('head_office_address', values?.head_office_address);
			form_data.append('is_delivery', values?.is_delivery);
			setFormSubmit(true);
			await dispatch(CreateProductMethod(form_data));
			await dispatch(productReset());
		},
	});

	useEffect(() => {
		GetAllStatesMethod(dispatch);
		GetAllProductTypesMethod(dispatch);
	}, []);

	useEffect(() => {
		if (isError) {
			HendleErrorsNotifications(toast, isError, errors, message, responseCode);
		}

		if (isSuccess && formSubmit) {
			setFormSubmit(false);
			HandleSuccessNotifications(toast, 'Product Create Successfuly');
			formik.resetForm();
		}
	}, [
		dispatch,
		isError,
		isSuccess,
		isLoading,
		formSubmit,
		message,
		errors,
		responseCode,
	]);

	const handleUploadImage = (e) => {
		formik.values.specifications = e.currentTarget.files[0];
	};

	return (
		<CreateContainer>
			<FormContainer onSubmit={formik.handleSubmit}>
				<HeaderStyle2>
					<Add /> Create
				</HeaderStyle2>
				<Grid container spacing={2}>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="company_name"
							label="Company Name"
							value={formik.values.company_name || []}
							onChange={formik.handleChange}
							error={
								formik.touched.company_name && Boolean(formik.errors.company_name)
							}
							helperText={
								formik.touched.company_name && formik.errors.company_name
							}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="company_email"
							label="Company Email"
							value={formik.values.company_email || []}
							onChange={formik.handleChange}
							error={formik.touched.company_email && Boolean(formik.errors.company_email)}
							helperText={formik.touched.company_email && formik.errors.company_email}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							type="text"
							id="input-with-icon-textfield"
							name="delivery_time"
							label="Delivery Time"
							value={formik.values.delivery_time || []}
							onChange={formik.handleChange}
							error={
								formik.touched.delivery_time &&
								Boolean(formik.errors.delivery_time)
							}
							helperText={
								formik.touched.delivery_time && formik.errors.delivery_time
							}
						/>
					</Grid>
					
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="head_office_address"
							label="Head Office Address"
							value={formik.values.head_office_address || []}
							onChange={formik.handleChange}
							error={formik.touched.head_office_address && Boolean(formik.errors.head_office_address)}
							helperText={formik.touched.head_office_address && formik.errors.head_office_address}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<FormControl style={{width:"100%"}}>
							<InputLabel id="demo-multiple-name-label">Covered States</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								name="covered_states"
								multiple
								value={formik.values.covered_states || []}
								onChange={formik.handleChange}
								input={<OutlinedInput label="Covered States" />}
							>
								<MenuItem
									value="all"
								>
								All
								</MenuItem>
								{AllStates?.map((state) => (
									<MenuItem
										key={state.id}
										value={state.abbr}
									>
										{state.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<FormControl style={{width:"100%"}}>
							<InputLabel id="demo-multiple-name-label">Is Delivery</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								name="is_delivery"
								value={formik.values.is_delivery}
								onChange={formik.handleChange}
								input={<OutlinedInput label="is_delivery" />}
							>
									<MenuItem
										value="1"
									>
										Yes
									</MenuItem>
									<MenuItem
										value="0"
									>
										No
									</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<FormButtonStyle startIcon={<Add />} type="submit">
						Create
					</FormButtonStyle>
				</Grid>
			</FormContainer>
		</CreateContainer>
	);
};

const CreateContainer = styled.div`
	margin: auto;
	width: 50%;
`;

export default CreateProduct;
