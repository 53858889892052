import React, { useEffect, useState } from 'react';
import { FormContainer } from '../../layouts/Styled/buttons/FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { Add, Edit } from '@material-ui/icons';
import styled from 'styled-components';
import { HeaderStyle2 } from '../../layouts/Styled/HeaderStyle';
import { FormButtonStyle } from '../../layouts/Styled/buttons/submitButton';
import {
	CreateProductMethod,
	GetSingleProductsSlice,
	UpdateProductMethod,
	productReset,
} from '../../redux/ProjectSlice/ProductsSlice';
import { toast } from 'react-toastify';
import { GetAllStatesMethod, GetAllProductTypesMethod } from '../../Components/HelperMethods/APISMethodsHelper';
import {
	HandleSuccessNotifications,
	HendleErrorsNotifications,
} from '../../Components/HelperMethods/HandleNotifications';
import { useParams } from 'react-router-dom';

function EditProduct() {
	const dispatch = useDispatch();
	const { product_id } = useParams();
	const {
		product,
		message,
		isSuccess,
		isError,
		isLoading,
		errors,
		responseCode,
		product_types,
	} = useSelector((state) => state.products);
	const productInfo = product?.data;
	const { all_states } = useSelector((state) => state.all_states);
	const { mainUser } = useSelector((state) => state.auth);

	// const AllTypes = product_types?.data;
	const AllStates = all_states?.data?.states;


	const [formSubmit, setFormSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			user_id: mainUser?.id,
			company_name 	: '',
			company_email 	: '',
			delivery_time 	: '',
			head_office_address 	: '',
			is_delivery 	: '',
			covered_states 	: [],
		},
		onSubmit: async (values) => {
			const form_data = new FormData();

			console.log(values)
			form_data.append('company_name', values?.company_name);
			form_data.append('company_email', values?.company_email);
			form_data.append('covered_states', values?.covered_states);
			form_data.append('delivery_time', values?.delivery_time);
			form_data.append('head_office_address', values?.head_office_address);
			form_data.append('is_delivery', values?.is_delivery);
			console.log(form_data)

			setFormSubmit(true);
			await dispatch(UpdateProductMethod({form_data:form_data, product_id:product_id}));
			await dispatch(productReset());
		},
	});

	useEffect(() => {
		GetAllStatesMethod(dispatch);
		// GetAllProductTypesMethod(dispatch);
	}, []);

	useEffect(() => {
		if (isError) {
			HendleErrorsNotifications(toast, isError, errors, message, responseCode);
		}
		if (isSuccess && formSubmit) {
			setFormSubmit(false);
			HandleSuccessNotifications(toast, 'Product Updated Successfuly');
			formik.resetForm();
		}
	}, [
		dispatch,
		isError,
		isSuccess,
		isLoading,
		formSubmit,
		message,
		errors,
		responseCode,
		formik,
	]);

	useEffect(() => {
		const GetSingleProduct = async () => {
			await dispatch(GetSingleProductsSlice(product_id));
			await dispatch(productReset());
		};
		GetSingleProduct();
	}, []);

	const handleUploadImage = (e) => {
		formik.values.specifications = e.currentTarget.files[0];
	};

	const [FormikValues, setFormikValues] = useState(true);

	useEffect(() => {
		if (productInfo && FormikValues) {
			setFormikValues(false);
			formik.values.company_name = productInfo?.company_name || '';
			formik.values.company_email = productInfo?.company_email || '';
			formik.values.covered_states = productInfo?.covered_states || [];
			formik.values.delivery_time = productInfo?.delivery_time || 0;
			formik.values.head_office_address = productInfo?.head_office_address || '';
			formik.values.is_delivery = productInfo?.is_delivery || 0;
		}
	}, [formik.values, productInfo, FormikValues]);

	return (
		<CreateContainer>
			<FormContainer onSubmit={formik.handleSubmit}>
				<HeaderStyle2>
					<Edit /> Edit{' '}
				</HeaderStyle2>
				<Grid container spacing={2}>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="company_name"
							label="Company Name"
							value={formik.values.company_name || []}
							onChange={formik.handleChange}
							error={
								formik.touched.company_name && Boolean(formik.errors.company_name)
							}
							helperText={
								formik.touched.company_name && formik.errors.company_name
							}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="company_email"
							label="Company Email"
							value={formik.values.company_email || []}
							onChange={formik.handleChange}
							error={formik.touched.company_email && Boolean(formik.errors.company_email)}
							helperText={formik.touched.company_email && formik.errors.company_email}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							type="text"
							id="input-with-icon-textfield"
							name="delivery_time"
							label="Delivery Time"
							value={formik.values.delivery_time || []}
							onChange={formik.handleChange}
							error={
								formik.touched.delivery_time &&
								Boolean(formik.errors.delivery_time)
							}
							helperText={
								formik.touched.delivery_time && formik.errors.delivery_time
							}
						/>
					</Grid>
					
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="head_office_address"
							label="Head Office Address"
							value={formik.values.head_office_address || []}
							onChange={formik.handleChange}
							error={formik.touched.head_office_address && Boolean(formik.errors.head_office_address)}
							helperText={formik.touched.head_office_address && formik.errors.head_office_address}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<FormControl style={{width:"100%"}}>
							<InputLabel id="demo-multiple-name-label">Covered States</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								multiple
								id="demo-multiple-name"
								name="covered_states"
								value={formik.values.covered_states || []}
								onChange={formik.handleChange}
								input={<OutlinedInput label="Covered States" />}
							>
								<MenuItem
									value="all"
								>
								All
								</MenuItem>
								{AllStates?.map((state) => (
									<MenuItem
										key={state.id}
										value={state.abbr}
									>
										{state.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<FormControl style={{width:"100%"}}>
							<InputLabel id="demo-multiple-name-label">Is Delivery</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								name="is_delivery"
								value={formik.values.is_delivery}
								onChange={formik.handleChange}
								input={<OutlinedInput label="is_delivery" />}
							>
									<MenuItem
										value="1"
									>
										Yes
									</MenuItem>
									<MenuItem
										value="0"
									>
										No
									</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<FormButtonStyle startIcon={<Edit />} type="submit">
						Update
					</FormButtonStyle>
				</Grid>
			</FormContainer>
		</CreateContainer>
	);
}
const CreateContainer = styled.div`
	margin: auto;
	width: 50%;
`;

export default EditProduct;
