import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GetAllProductsMethod } from '../../Components/HelperMethods/APISMethodsHelper';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import LoadingAnimation from '../../Components/LoadingAnimation';
import DeletePopUp from './PoPUp/DeletePopUp';
import {
	DeleteProductSlice,
	productReset,
} from '../../redux/ProjectSlice/ProductsSlice';
import {
	HandleSuccessNotifications,
	HendleErrorsNotifications,
} from '../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';
import { Download } from '@mui/icons-material';
import { Link, NavLink } from 'react-router-dom';

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [createData('Frozen yoghurt', 159, 6.0, 24, 4.0)];

const ShowProducts = () => {
	const dispatch = useDispatch();
	const {
		products,
		isError,
		isSuccess,
		isLoading,
		errors,
		responseCode,
		message,
	} = useSelector((state) => state.products);

	// Handle Delete PoPUp
	const [open, setOpen] = useState(false);
	const [data, setData] = useState({});
	const [submitStateus, setSubmitStatus] = useState(false);
	const hendleClose = () => {
		setOpen(false);
	};

	const handleDeleteSubmit = async () => {
		setOpen(false);
		setSubmitStatus(true);
		await dispatch(DeleteProductSlice(data.id));
		await GetAllProductsMethod(dispatch);
	};

	const allProducts = products?.data;

	console.log('allProducts', allProducts)
	useEffect(() => {
		GetAllProductsMethod(dispatch);
	}, []);

	const HandleDeleteProduct = (data) => {
		setOpen(true);
		setData(data);
	};

	useEffect(() => {
		if (isError) {
			HendleErrorsNotifications(toast, errors, message);
		}
		if (isSuccess && submitStateus) {
			setSubmitStatus(false);
			HandleSuccessNotifications(toast, 'Product Deleted Successfuly');
		}
	}, [errors, isError, isSuccess, message, submitStateus]);

	return (
		<>
			{isLoading && <LoadingAnimation />}
			{!isLoading && (
				<TableStyle>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell> # </TableCell>
									<TableCell align="center">Name</TableCell>
									<TableCell align="center">Email</TableCell>
									<TableCell align="center">Covered States</TableCell>
									<TableCell align="center">Delivery Time</TableCell>
									<TableCell align="center">Office Address</TableCell>
									<TableCell align="center">Delivery</TableCell>
									<TableCell align="center">Acions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{allProducts?.map((product, index) => (
									<TableRow
										key={product?.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{index + 1}
										</TableCell>
										<TableCell align="center">{product?.company_name}</TableCell>
										<TableCell
											align="center"
										>
											{product?.company_email}
										</TableCell>
										<TableCell align="center">
											{product?.covered_states}
										</TableCell>
										<TableCell align="center">
											{product?.delivery_time}
										</TableCell>
										<TableCell align="center">{product?.head_office_address}</TableCell>
										<TableCell align="center">{product?.is_delivery ? "Yes" : "No"}</TableCell>
										<TableCell align="center">
											<Button
												className="text-main"
												onClick={(e) => HandleDeleteProduct(product)}
											>
												<Delete />
											</Button>
											<NavLink to={'edit/' + product?.id}>
												<Button className="text-main">
													<Edit color="primary" />
												</Button>
											</NavLink>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<DeletePopUp
						open={open}
						hendleClose={hendleClose}
						title={data.name}
						handleDeleteSubmit={handleDeleteSubmit}
					/>
				</TableStyle>
			)}
		</>
	);
};

const TableStyle = styled.div`
	th {
		font-size: 1.1em;
		padding: 15px;
	}
`;

export default ShowProducts;
